<template>
  <div
    v-if="isInitialized"
    class="h-full w-full embedded-form-container relative"
  >
    <!-- Success Message -->
    <div v-if="showSuccessMessage" class="fixed inset-0 bg-white bg-opacity-90 flex flex-col items-center justify-center z-50 p-4">
      <div class="text-center">
        <div class="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-green-100">
          <svg class="h-12 w-12 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
          </svg>
        </div>
        <h3 class="mt-3 text-2xl font-medium text-gray-900">Quote Request Submitted!</h3>
        <div class="mt-4 text-lg text-gray-700">
          <p>Thank you for submitting your quote request. Our team will review your information and contact you shortly.</p>
          <p class="mt-2">Need immediate assistance? Call us at <a href="tel:+18002699137" class="text-blue-600 hover:text-blue-800">(800) 269-9137</a></p>
        </div>
      </div>
    </div>

    <!-- Error Message Modal -->
    <div v-if="showErrorMessage" 
         style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100vw; height: 100vh; z-index: 9999;">
      <!-- Backdrop -->
      <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); backdrop-filter: blur(4px);"></div>
      
      <!-- Error Modal -->
      <div class="animate-errorIn" 
           style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 400px; max-width: calc(100vw - 32px); background: white; border-radius: 12px; box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04); padding: 24px; z-index: 9999;">
        
        <!-- Error Icon - Fixed perfect centering -->
        <div style="margin: 0 auto 20px; width: 64px; height: 64px; position: relative;">
          <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: #FEE2E2; border-radius: 50%;"></div>
          <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <svg style="width: 32px; height: 32px; color: #DC2626; display: block;" 
                 fill="none" 
                 stroke="currentColor" 
                 viewBox="0 0 24 24">
              <path stroke-linecap="round" 
                    stroke-linejoin="round" 
                    stroke-width="2" 
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
            </svg>
          </div>
        </div>

        <!-- Error Content -->
        <div style="text-align: center; margin-bottom: 24px;">
          <h3 style="font-size: 20px; font-weight: 600; color: #1F2937; margin-bottom: 12px;">
            Submission Error
          </h3>

          <div style="color: #4B5563;">
            <p style="font-size: 16px; line-height: 1.5; margin-bottom: 12px;">{{ errorMessage }}</p>
            <p style="font-size: 14px;">
              Need help? Call us at 
              <a href="tel:+18002699137" 
                 style="color: #2563EB; font-weight: 600; text-decoration: none;">
                (800) 269-9137
              </a>
            </p>
          </div>
        </div>

        <!-- Action Buttons - Absolutely centered with proper spacing -->
        <div style="width: 100%; text-align: center;">
          <div style="display: inline-block;">
            <button @click="resetForm" 
                    style="display: inline-block; min-width: 120px; padding: 10px 20px; border: 1px solid #D1D5DB; border-radius: 6px; background: white; color: #374151; font-size: 14px; font-weight: 500; transition: all 0.2s; margin: 0 8px;">
              Start Over
            </button>
            <button @click="showErrorMessage = false" 
                    style="display: inline-block; min-width: 120px; padding: 10px 20px; border: none; border-radius: 6px; background: #2563EB; color: white; font-size: 14px; font-weight: 500; transition: all 0.2s; margin: 0 8px;">
              Try Again
            </button>
          </div>
        </div>

        <!-- Close Button - Fixed positioning -->
        <button @click="showErrorMessage = false" 
                style="position: absolute; top: 12px; right: 12px; padding: 8px; color: #9CA3AF; transition: color 0.2s; line-height: 0; z-index: 10; background: transparent; border: none;">
          <svg style="width: 20px; height: 20px; display: block;" 
               fill="none" 
               stroke="currentColor" 
               viewBox="0 0 24 24">
            <path stroke-linecap="round" 
                  stroke-linejoin="round" 
                  stroke-width="2" 
                  d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>

    <!-- Loading Indicator -->
    <Transition name="fade">
      <div v-if="isLoading || initialLoading" 
           class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <img src="@/assets/images/gifs/loading.gif" 
             alt="Loading..." 
             class="w-20 h-20 animate-pulse" />
      </div>
    </Transition>

    <!-- Form content only shows when not loading and no message is being displayed -->
    <template v-if="!isLoading && !showSuccessMessage && !showErrorMessage">
      <div v-if="showError" class="w-full md:w-1/3 mb-4">
        <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span class="block sm:inline">{{ errorMessage }}</span>
        </div>
      </div>

      <div v-if="!isSuccess" 
           class="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg p-4 sm:p-6 md:p-8"
      >
        <h2 class="text-xl sm:text-2xl font-bold text-gray-800 text-center mb-4 sm:mb-6">
          Home Insurance Quote
        </h2>

        <div class="w-full">
          <!-- Form fields with improved spacing -->
          <div class="space-y-4 sm:space-y-6">
            <!-- Each form group -->
            <div class="form-group">
              <label for="firstName" class="block text-sm font-medium text-gray-700 mb-1">
                First Name <span class="text-red-500">*</span>
              </label>
              <InputText
                id="firstName"
                v-model="firstName"
                type="text"
                placeholder="Your First Name"
                class="w-full p-2 sm:p-3 border border-gray-300 rounded-md shadow-sm"
                :class="{ 'border-red-500': firstNameError }"
              />
            </div>

            <div class="form-group">
              <label for="lastName" class="block text-sm font-medium text-gray-700 mb-1">
                Last Name <span class="text-red-500">*</span>
              </label>
              <InputText
                id="lastName"
                v-model="lastName"
                type="text"
                placeholder="Your Last Name"
                class="w-full p-2 sm:p-3 border border-gray-300 rounded-md shadow-sm"
                :class="{ 'border-red-500': lastNameError }"
              />
            </div>

            <div class="form-group">
              <label for="email" class="block text-sm font-medium text-gray-700 mb-1">
                Email <span class="text-red-500">*</span>
              </label>
              <InputText
                id="email"
                v-model="email"
                type="email"
                placeholder="Your Email"
                class="w-full p-2 sm:p-3 border border-gray-300 rounded-md shadow-sm"
                :class="{ 'border-red-500': emailError }"
              />
            </div>

            <div class="form-group">
              <label for="phone" class="block text-sm font-medium text-gray-700 mb-1">
                Phone Number <span class="text-red-500">*</span>
              </label>
              <InputMask
                id="phone"
                v-model="phone"
                mask="(999) 999-9999"
                placeholder="(555) 555-5555"
                class="w-full p-2 sm:p-3 border border-gray-300 rounded-md shadow-sm"
                :class="{ 'border-red-500': phoneError }"
              />
            </div>

            <!-- Additional Information Field -->
            <div class="relative">
              <label for="message" class="block text-gray-700 text-sm font-medium mb-2">
                Additional Information
                <!-- Info Icon with Tooltip -->
                <span
                  class="tooltip-container ml-1 text-blue-500 cursor-pointer"
                  @click="tooltips.additionalInfo = !tooltips.additionalInfo"
                  tabindex="0"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M18 10A8 8 0 112 10a8 8 0 0116 0zm-8-4a1 1 0 100 2 1 1 0 000-2zm1 4a1 1 0 00-2 0v4a1 1 0 002 0v-4z" clip-rule="evenodd" />
                  </svg>
                  <!-- Tooltip -->
                  <div v-if="tooltips.additionalInfo" class="tooltip-content">
                    Please provide any additional details that would help us better understand your insurance needs. For example:
                    <ul class="list-disc list-inside mt-2">
                      <li>Current insurance provider and policy expiration date</li>
                      <li>Square footage of the property</li>
                      <li>Year built</li>
                      <li>Any recent upgrades or renovations</li>
                      <li>Special features or concerns</li>
                    </ul>
                  </div>
                </span>
              </label>
              <textarea
                v-model="message"
                id="message"
                rows="4"
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Please provide any additional details that would help us better understand your insurance needs."
              ></textarea>
            </div>

            <!-- File upload section with improved mobile layout -->
            <div class="w-full space-y-4 mt-6">
              <h3 class="text-lg font-semibold text-gray-700">Optional Documents</h3>
              
              <div class="grid gap-4 sm:gap-6">
                <div v-for="(field, type) in uploadFields" 
                     :key="type" 
                     class="relative bg-gray-50 p-3 sm:p-4 rounded-lg"
                >
                  <label :for="type" class="block text-gray-700 text-sm font-medium mb-2">
                    {{ field.label }}
                    <!-- Info Icon with Tooltip -->
                    <span
                      class="tooltip-container ml-1 text-blue-500 cursor-pointer"
                      @click.stop="showTooltip(type, !tooltips[type])"
                      @mouseenter="showTooltip(type, true)"
                      @mouseleave="showTooltip(type, false)"
                      tabindex="0"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M18 10A8 8 0 112 10a8 8 0 0116 0zm-8-4a1 1 0 100 2 1 1 0 000-2zm1 4a1 1 0 00-2 0v4a1 1 0 002 0v-4z" clip-rule="evenodd" />
                      </svg>
                      <div v-if="tooltips[type]" class="tooltip-content" @click.stop>
                        {{ field.tooltip }}
                      </div>
                    </span>
                  </label>
                  
                  <!-- File input -->
                  <input
                    :id="type"
                    type="file"
                    class="hidden"
                    :accept="allowedExtensions.join(',')"
                    @change="(e) => handleFileChange(type, e)"
                    :ref="(el) => fileInputRefs[type] = el"
                  />
                  
                  <!-- Upload zone -->
                  <div
                    :class="[
                      'upload-zone flex flex-col rounded-lg border-2 w-full p-4 group text-center cursor-pointer',
                      files[type] ? 'border-green-500' : 'border-dashed hover:border-blue-500'
                    ]"
                    @click="triggerFileInput(type)"
                    @dragover="handleDragOver"
                    @dragleave="handleDragLeave"
                    @drop="(e) => handleDrop(e, type)"
                  >
                    <div v-if="files[type]" class="flex flex-col items-center">
                      <svg class="h-10 w-10 text-green-500 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <!-- Checkmark icon -->
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <p class="text-sm text-gray-700">File Attached:</p>
                      <p class="mt-1 text-sm font-medium text-gray-900">{{ files[type].name }}</p>
                      <button
                        type="button"
                        @click="removeFile(type)"
                        class="mt-2 text-red-500 hover:underline text-sm"
                      >Remove File</button>
                    </div>
                    <div v-else class="h-full w-full text-center flex flex-col items-center justify-center">
                      <span class="text-blue-600 text-3xl mb-2">+</span>
                      <p class="text-sm text-gray-500">Click to upload or drag and drop</p>
                      <p class="text-xs text-gray-400 mt-1">Accepted files: PDF, DOC, DOCX, JPG, PNG</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Move required fields notice to bottom -->
          <div class="required-field-notice mt-4 mb-2">
            <div class="flex items-center justify-start space-x-2">
              <span class="text-red-500 font-bold">*</span>
              <span class="text-gray-600">All fields marked with an asterisk are required</span>
            </div>
          </div>

          <!-- Submit button -->
          <Button
            :label="isLoading ? 'Submitting...' : 'Submit Quote Request'"
            class="mt-4 w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg"
            @click="debouncedSubmit"
            :disabled="isLoading"
            :loading="isLoading"
          />
        </div>
      </div>

      <!-- Success message for non-embedded view -->
      <div v-else-if="!props.isEmbedded" class="my-8 md:my-24 w-full md:w-1/3 bg-white shadow-lg mx-auto rounded-lg p-6">
        <div class="text-center">
          <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg class="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
          </div>
          <h3 class="mt-3 text-lg font-medium text-gray-900">Quote Request Submitted!</h3>
          <div class="mt-2 text-sm text-gray-500">
            <p>Thank you for submitting your quote request. Our team will review your information and contact you shortly.</p>
            <p class="mt-2">A confirmation email has been sent to {{ email }}.</p>
            <p class="mt-4 font-semibold">Need immediate assistance?</p>
            <p class="mt-1">
              Call us at: <a href="tel:+18002699137" class="text-blue-600 hover:text-blue-800">(800) 269-9137</a>
            </p>
          </div>
          <button
            @click="resetForm"
            class="mt-6 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Submit Another Quote
          </button>
        </div>
      </div>

      <!-- Embedded success message -->
      <div v-if="isSuccess && props.isEmbedded" class="hidden">
        <!-- This is hidden; success handling is delegated to parent -->
      </div>
    </template>
  </div>
  <div v-else class="loading-placeholder">
    <!-- Add a loading spinner or placeholder -->
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted, onUpdated, nextTick, computed } from 'vue';
import axios from 'axios';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import { useNotification } from '@kyvg/vue3-notification';
import { debounce } from 'lodash';

const props = defineProps({
  isEmbedded: {
    type: Boolean,
    default: false
  }
});

const isEmbedded = ref(true);
const isInitialized = ref(true);

const firstName = ref('');
const lastName = ref('');
const email = ref('');
const phone = ref('');
const message = ref('');
const isSuccess = ref(false);
const isLoading = ref(false);
const firstNameError = ref(false);
const lastNameError = ref(false);
const emailError = ref(false);
const phoneError = ref(false);
const errorMessage = ref('');
const showError = ref(false);

const notification = useNotification();

const files = ref({
  fourPoint: null,
  windMit: null,
  decPage: null
});

const initialLoading = ref(false);

const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png'];
const allowedExtensions = ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png'];

const retryCount = ref(0);
const maxRetries = 3;
const isSubmitting = ref(false);
const hasError = ref(false);

const validateFirstName = () => {
  firstNameError.value = firstName.value.trim() === '';
};

const validateLastName = () => {
  lastNameError.value = lastName.value.trim() === '';
};

const validateEmail = () => {
  emailError.value = !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email.value);
};

const validatePhone = () => {
  const rawPhoneValue = phone.value.replace(/\D/g, '');
  phoneError.value = !/^\+?[1-9]\d{1,14}$/.test(rawPhoneValue);
};

const handleFileChange = (type, event) => {
  const file = event.target.files[0];
  if (!file) {
    files.value[type] = null;
    return;
  }

  try {
    console.log('File Details:', {
      name: file.name,
      size: file.size,
      type: file.type
    });

    const maxSize = 150 * 1024 * 1024; // 150MB in bytes
    if (file.size > maxSize) {
      throw new Error(`File size must be less than ${formatFileSize(maxSize)}`);
    }

    // Validate file type
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png'];
    if (!allowedTypes.includes(file.type)) {
      throw new Error('Invalid file type. Please upload a PDF, DOC, DOCX, JPG, or PNG file.');
    }

    files.value[type] = file;
    console.log('File accepted:', type);
  } catch (error) {
    console.error('File Upload Error:', error);
    notification.notify({
      title: 'File Upload Error',
      text: error.message,
      type: 'error',
      duration: 5000
    });
  }
};

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const resetForm = () => {
  firstName.value = '';
  lastName.value = '';
  email.value = '';
  phone.value = '';
  message.value = '';
  files.value = {
    fourPoint: null,
    windMit: null,
    decPage: null
  };
  isSuccess.value = false;
  hasError.value = false;
  errorMessage.value = '';
  showErrorMessage.value = false;
  retryCount.value = 0;
  
  firstNameError.value = false;
  lastNameError.value = false;
  emailError.value = false;
  phoneError.value = false;

  if (props.isEmbedded) {
    window.parent.postMessage({
      type: 'formReset',
      timestamp: new Date().toISOString()
    }, '*');
  }
};

const sendMessage = (type, data = {}) => {
  if (props.isEmbedded) {
    window.parent.postMessage({
      type,
      ...data
    }, '*');
  }
};

const showSuccessMessage = ref(false);
const showErrorMessage = ref(false);

const submitForm = async () => {
  if (isSubmitting.value) return;
  
  try {
    isSubmitting.value = true;
    isLoading.value = true;
    showErrorMessage.value = false;
    errorMessage.value = '';

    // Validation
    validateFirstName();
    validateLastName();
    validateEmail();
    validatePhone();

    if (firstNameError.value || lastNameError.value || emailError.value || phoneError.value) {
      const errors = [];
      if (firstNameError.value) errors.push('First Name');
      if (lastNameError.value) errors.push('Last Name');
      if (emailError.value) errors.push('Email');
      if (phoneError.value) errors.push('Phone');
      
      throw new Error(`Please check the following fields: ${errors.join(', ')}`);
    }

    const formData = new FormData();
    formData.append('firstName', firstName.value.trim());
    formData.append('lastName', lastName.value.trim());
    formData.append('email', email.value.trim());
    formData.append('phone', phone.value.trim());
    formData.append('message', message.value.trim());

    for (const [key, file] of Object.entries(files.value)) {
      if (file && file instanceof File) {
        if (file.size > 150 * 1024 * 1024) { // 150MB limit
          throw new Error(`${file.name} is too large. Maximum file size is 150MB.`);
        }
        formData.append(key, file);
      }
    }

    if (props.isEmbedded) {
      window.parent.postMessage({
        type: 'formSubmitting',
        timestamp: new Date().toISOString()
      }, '*');
    }

    const response = await fetch('/api/submit-guardian-angel-quote', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      credentials: 'same-origin'
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to submit quote request');
    }

    isSuccess.value = true;
    showSuccessMessage.value = true;
    retryCount.value = 0; 

    if (props.isEmbedded) {
      window.parent.postMessage({
        type: 'formSubmitted',
        success: true,
        timestamp: new Date().toISOString()
      }, '*');
    }

  } catch (error) {
    console.error('Form submission error:', error);
    hasError.value = true;
    errorMessage.value = error.message;
    showErrorMessage.value = true;

    // Retry logic
    if (retryCount.value < maxRetries && error.message.includes('network')) {
      retryCount.value++;
      await new Promise(resolve => setTimeout(resolve, 1000 * retryCount.value));
      return submitForm(); // Retry submission
    }

    // Send error to parent frame
    if (props.isEmbedded) {
      window.parent.postMessage({
        type: 'formError',
        error: errorMessage.value,
        timestamp: new Date().toISOString()
      }, '*');
    }

  } finally {
    isSubmitting.value = false;
    isLoading.value = false;
  }
};

const debouncedSubmit = debounce(() => {
  if (!isSubmitting.value) {
    submitForm();
  }
}, 300, { leading: true, trailing: false });

const handleDragOver = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const dropZone = event.currentTarget;
  dropZone.classList.add('border-blue-500');
};

const handleDragLeave = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const dropZone = event.currentTarget;
  dropZone.classList.remove('border-blue-500');
};

const handleDrop = (event, type) => {
  event.preventDefault();
  event.stopPropagation();
  const dropZone = event.currentTarget;
  dropZone.classList.remove('border-blue-500');

  const droppedFiles = event.dataTransfer.files;
  if (droppedFiles.length > 0) {
    const fakeEvent = { target: { files: droppedFiles } };
    handleFileChange(type, fakeEvent);
  }
};

const removeFile = (type) => {
  files.value[type] = null;
  if (fileInputRefs.value[type]) {
    fileInputRefs.value[type].value = '';
  }
};

const fileInputRefs = ref({});

const triggerFileInput = (type) => {
  console.log('Triggering file input for:', type);
  console.log('Available refs:', Object.keys(fileInputRefs.value));
  console.log('Ref exists?', !!fileInputRefs.value[type]);
  
  if (fileInputRefs.value[type]) {
    fileInputRefs.value[type].click();
  } else {
    console.error('Missing ref for:', type);
  }
};

// Send height updates to parent (guardianangelinspections.com)
const updateParentHeight = async () => {
    await nextTick();
    const height = document.documentElement.offsetHeight;
    // Send to any parent domain since we're in an iframe
    window.parent.postMessage({
        type: 'formHeight',
        height: height
    }, '*');
};

onMounted(() => {
    isInitialized.value = true;
    initialLoading.value = false;
    
    // Notify parent frame immediately that we're ready
    if (props.isEmbedded) {
        window.parent.postMessage({
            type: 'formHeight',
            height: document.documentElement.scrollHeight
        }, '*');
    }
});

onUpdated(updateParentHeight);

// Watch for any changes that might affect height
watch([
    firstName, 
    lastName, 
    email, 
    phone, 
    message, 
    files, 
    showSuccessMessage, 
    showErrorMessage, 
    isLoading
], () => {
    updateParentHeight();
}, { deep: true });

watch(isSuccess, (newValue) => {
  if (props.isEmbedded) {
    window.parent.postMessage({
      type: newValue ? 'formSubmitted' : 'formReset'
    }, '*');
  }
});

const tooltips = ref({
  fourPoint: false,
  windMit: false,
  decPage: false,
  additionalInfo: false,
});

const showTooltip = (field, value) => {
  tooltips.value[field] = value;
};

const uploadFields = {
  fourPoint: {
    label: 'Four-Point Inspection Document (If you have one)',
    tooltip: 'A Four-Point Inspection evaluates the main systems of your home. Often required for insurance.'
  },
  windMit: {
    label: 'Wind Mitigation Report (If you have one)',
    tooltip: 'A Wind Mitigation report can help reduce your insurance premiums by documenting wind-resistant features.'
  },
  decPage: {
    label: 'Declarations Page (If you have one)',
    tooltip: 'A Declarations Page summarizes your current insurance policy, helping us understand your coverage needs.'
  }
};

// **Cleanup on unmount**
onUnmounted(() => {
  if (debouncedSubmit && debouncedSubmit.cancel) {
    debouncedSubmit.cancel();
  }
});

// Add watchers for validation
watch([firstName, lastName, email, phone], () => {
  if (hasError.value) {
    validateFirstName();
    validateLastName();
    validateEmail();
    validatePhone();
  }
});

// Add computed property for field errors
const fieldErrors = computed(() => ({
  firstName: firstNameError.value ? 'First name is required' : null,
  lastName: lastNameError.value ? 'Last name is required' : null,
  email: emailError.value ? 'Please enter a valid email address' : null,
  phone: phoneError.value ? 'Please enter a valid phone number' : null
}));

onMounted(() => {
  if (showErrorMessage.value) {
    const modal = document.querySelector('.error-modal');
    if (modal) {
      const rect = modal.getBoundingClientRect();
      console.log('Modal position:', {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        viewportHeight: window.innerHeight,
        viewportWidth: window.innerWidth
      });
    }
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
  background-color: #4a5568; 
  color: white;
  padding: 1rem;  
  border-radius: 0.375rem;  
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 10;
  max-width: 400px;  
  width: max-content;  
  min-width: 300px;  
  white-space: normal;
}

.tooltip-content a {
  text-decoration: underline;
  color: #63b3ed; 
}

.tooltip-content a:hover {
  color: #4299e1; 
}

.tooltip-content::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #4a5568 transparent transparent transparent;
}

@media (max-width: 640px) {
  .tooltip-content {
    max-width: 90vw;  
    min-width: 280px;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}


@media (max-width: 480px) {
  .tooltip-content {
    position: fixed;
    bottom: 20px;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);  
    max-width: none;
    min-width: 0;
    margin: 0 auto;
    transform: none;
  }
}

.embedded-form-container {
  position: relative;
  width: 100%;
  height: auto;
  min-height: auto !important;
  max-height: none !important;
  background: #fff;
  margin: 0 !important;
  padding: 0 0 100px 0 !important;
  z-index: 0 !important;
}

@media (max-width: 767px) {
    .embedded-form-container {
        padding: 0 0 150px 0 !important;
        position: relative !important;
        z-index: 1 !important;
    }

    .form-container {
        position: relative !important;
        z-index: 2 !important;
    }

    button, 
    input[type="submit"],
    .upload-zone,
    .tooltip-container {
        position: relative !important;
        z-index: 3 !important;
    }

    .success-message,
    .error-message {
        z-index: 13 !important;
    }
}

.form-container {
  width: 100%;
  background: #fff;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.form-container > *:last-child {
  margin-bottom: 0.5rem;
}

.w-full.max-w-2xl {
  padding: 0.75rem !important;
}

.success-message,
.error-message,
.loading-overlay {
  z-index: 1200 !important;
}

.required-field-notice {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.required-field-notice span {
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 640px) {
  .required-field-notice {
    margin-bottom: 1rem;
  }
  
  .required-field-notice span {
    font-size: 0.875rem;
  }
}

.form-group {
  margin-bottom: 0.5rem !important;
}

:deep(*) {
  opacity: 1 !important;
  visibility: visible !important;
  display: revert !important;
}

@media (max-width: 640px) {
  .form-container {
    padding: 0.75rem;
    min-height: 600px;
  }
}

.upload-zone {
    border: 2px dashed #e2e8f0;
    background: #f8fafc;
    transition: all 0.3s ease;
    position: relative;
    width: 100%;
    cursor: pointer;
    height: 120px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.upload-zone:hover {
    border-color: #3b82f6;
    background: #f0f7ff;
}

input[type="file"] {
    display: none !important;
}

/* Container for both empty state and file attached state */
.upload-zone > div {
    width: 100%;
    height: 120px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.75rem !important;
    position: absolute;
    top: 0;
    left: 0;
}

/* Mobile specific styles */
@media (max-width: 640px) {
    .upload-zone,
    .upload-zone > div {
        height: 100px !important;
        padding: 0.5rem !important;
    }
    
    .upload-zone > div {
        padding: 0.5rem !important;
    }
}

/* Remove min-height from file attached state */
.upload-zone.border-green-500 {
    height: 120px !important;
}

/* File info display */
.upload-zone .flex.flex-col.items-center {
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 0.5rem; /* Slightly reduced gap */
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
}

/* Icon styles */
.upload-zone svg {
    flex-shrink: 0;
    height: 2rem; /* Slightly reduced from 2.5rem */
    width: 2rem;
}

/* Text styles */
.upload-zone p {
    margin: 0;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.2;
}

/* File name display */
.upload-zone .text-sm.font-medium.text-gray-900 {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.2;
}

/* Error animation */
@keyframes errorIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -55%) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.animate-errorIn {
  animation: errorIn 0.3s ease-out forwards;
}

/* Button hover states */
button[style*="background: white"]:hover {
  background: #F3F4F6 !important;
  transform: translateY(-1px);
}

button[style*="background: #2563EB"]:hover {
  background: #1D4ED8 !important;
  transform: translateY(-1px);
}

/* Mobile adjustments */
@media (max-width: 480px) {
  div[style*="width: 400px"] {
    width: calc(100% - 32px) !important;
    max-width: 400px;
  }
}

/* Mobile Optimizations */
@media (max-width: 480px) {
  div[style*="padding: 24px"] {
    padding: 20px !important;
  }

  @media (max-width: 360px) {
    div[style*="display: flex; justify-content: center"] {
      flex-direction: column !important;
      gap: 12px !important;
    }

    button[style*="min-width: 100px"] {
      width: 100% !important;
    }
  }

  /* Adjust font sizes for mobile */
  h3[style*="font-size: 20px"] {
    font-size: 18px !important;
  }

  p[style*="font-size: 16px"] {
    font-size: 15px !important;
  }

  p[style*="font-size: 14px"] {
    font-size: 13px !important;
  }
}

@media (max-width: 360px) {
  div[style*="display: flex; align-items: center; justify-content: center"] {
    flex-direction: column !important;
  }
  
  button[style*="min-width: 120px"] {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 12px !important;
  }
  
  button[style*="min-width: 120px"]:last-child {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 360px) {
  div[style*="text-align: center"] > div {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }

  button[style*="display: inline-block"] {
    width: 100% !important;
    margin: 6px 0 !important;
  }
}

/* Add these styles to ensure close button stays fixed */
@media (max-width: 480px) {
  button[style*="position: absolute"] {
    top: 12px !important;
    right: 12px !important;
    padding: 8px !important;
    position: absolute !important;
  }
}

/* Form group spacing */
.form-group {
    margin-bottom: 0.5rem !important;
}

/* Label spacing */
label.block {
    margin-bottom: 0.2rem !important;
}

/* Space between sections */
.space-y-4 {
    margin-top: 0.5rem !important;
}

/* Adjust heading margins */
h2.text-xl {
    margin-bottom: 0.75rem !important;
}

/* Adjust file upload section spacing */
.w-full.space-y-4.mt-6 {
    margin-top: 0.75rem !important;
}

/* Grid gap adjustments */
.grid.gap-4 {
    gap: 0.5rem !important;
}

/* Additional information field spacing */
.relative {
    margin-bottom: 0.5rem !important;
}

/* Required fields notice spacing */
.required-field-notice {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

/* Submit button spacing */
Button {
    margin-top: 0.5rem !important;
}

/* Textarea height */
textarea {
    height: 60px !important;
}

/* Mobile adjustments */
@media (max-width: 640px) {
    .w-full.max-w-2xl {
        padding: 0.5rem !important;
    }
    
    .form-group {
        margin-bottom: 0.4rem !important;
    }
    
    .space-y-4 {
        margin-top: 0.4rem !important;
    }

    .upload-zone,
    .upload-zone > div {
        height: 100px !important;
        padding: 0.5rem !important;
    }
}

/* Embedded form container padding adjustment */
.embedded-form-container {
    padding: 0 0 100px 0 !important;
}

@media (max-width: 767px) {
    .embedded-form-container {
        padding: 0 0 150px 0 !important;
    }
}
</style>
